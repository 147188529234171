import { mapGetters } from 'vuex';
import weightedMean from 'weighted-mean';
import { Enums, Providers } from '@flightscope/baseball-stats';
import { unitSymbolProvider } from '@/filters/units';
import { roundFractionals } from '@/utils/formatters';
import { mapPitchingSummaryStats } from '@/utils/mappers';
import { PitchTypeSeriesColorMapping as PitchColors } from '@/components/ui/charts/ColorMapping';
import { ReportsEnum } from '../configs/ReportsEnum';
import ExportCsvBtn from '@/modules/session/views/ExportCsvBtn.vue';
import { EReportContext } from '../../../enums/reports';

const NoData = () => import('@/components/core/NoData.vue');

export default {
  name: 'ProfilePitchingSummary',

  props: {
    reportLoading: {
      type: Boolean,
    },
    player: {
      type: Object,
    },
    report: {
      type: Array,
      default: () => [],
    },
    dynamicFilters: {
      type: Object,
      default: () => {},
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    showDummyData: {
      type: Boolean,
      default: false,
    },
    showExport: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ExportCsvBtn,
    ThExt: () => import('@/components/ui/tables/ThExt.vue'),
    ProfileSummaryExpandable: () => import(
      /* webpackChunkName: "playerProfiles" */
      '@/modules/player-profiles/reports/ProfileSummaryExpandable.vue'),
    NoData,
  },

  data() {
    return {
      chartSeries: null,
      mixedChartSeries: null,
      expanded: [],
      enabledCharts: [
        { key: 'pitching-pitchers-performance', config: ReportsEnum['pitching-pitchers-performance'] },
        { key: 'pitching-plate-discipline', config: ReportsEnum['pitching-plate-discipline'] },
      ],
      PitchColors,
      tableOptions: {
        sortBy: [Enums.PitchingStats.NumberOfPitches.key],
        sortDesc: [true],
      },
      pitchingStatsEnum: Enums.PitchingStats,
    };
  },

  computed: {
    ...mapGetters(['selectedUnitsSystem']),
    ctx() {
      return EReportContext.PITCHING;
    },
    pitchingSummaryDataArray() {
      if (this.report && this.report.length) {
        return Providers.PitchingSummaryDataProvider(this.report, undefined, this.dynamicFilters);
      }
      return [];
    },
    pitchingSummaryData() {
      if (this.pitchingSummaryDataArray.length) {
        return this.pitchingSummaryDataArray[0];
      }
      return null;
    },
    // TODO: implement the same logic as in batting summary for displaying data and totals
    mappedData() {
      if (this.pitchingSummaryData) {
        // TODO: remove converting data in mapper. It should be converted later.
        return mapPitchingSummaryStats(this.pitchingSummaryData.pitchTypes, this.selectedUnitsSystem.system);
      }
      return [];
    },
    headers() {
      return [
        { text: 'Pitch Type', value: 'PitchTypeLabel' },
        {
          text: Enums.PitchingStats.NumberOfPitches.short,
          value: Enums.PitchingStats.NumberOfPitches.key,
          title: Enums.PitchingStats.NumberOfPitches.description,
          align: 'center',
          sortable: true,
        },
        {
          text: Enums.PitchingStats.PitchUsage.name,
          value: Enums.PitchingStats.PitchUsage.key,
          align: 'center',
          title: Enums.PitchingStats.PitchUsage.description,
        },
        {
          title: Enums.PitchingStats.PitchSpeedAvg.description,
          text: `AVG Velocity [${unitSymbolProvider('SPEED', this.selectedUnitsSystem.system)}]`,
          value: Enums.PitchingStats.PitchSpeedAvg.key,
          align: 'center',
        },
        {
          title: Enums.PitchingStats.PitchSpeedMax.description,
          text: `MAX Velocity [${unitSymbolProvider(Enums.PitchingStats.PitchSpeedMax.type, this.selectedUnitsSystem.system)}]`,
          value: Enums.PitchingStats.PitchSpeedMax.key,
          align: 'center',
        },
        {
          title: Enums.PitchingStats.PitchSpinAvg.description,
          text: `AVG Spin [${unitSymbolProvider(Enums.PitchingStats.PitchSpinAvg.type, this.selectedUnitsSystem.system)}]`,
          value: Enums.PitchingStats.PitchSpinAvg.key,
          align: 'center',
        },
        {
          title: Enums.PitchingStats.PitchMovementHAvg.description,
          text: `AVG H Movement [${unitSymbolProvider(Enums.PitchingStats.PitchMovementHAvg.type, this.selectedUnitsSystem.system)}]`,
          value: Enums.PitchingStats.PitchMovementHAvg.key,
          align: 'center',
        },
        {
          title: Enums.PitchingStats.PitchMovementVAvg.description,
          text: `AVG V Movement [${unitSymbolProvider(Enums.PitchingStats.PitchMovementVAvg.type, this.selectedUnitsSystem.system)}]`,
          value: Enums.PitchingStats.PitchMovementVAvg.key,
          align: 'center',
        },
        {
          title: Enums.PitchingStats.ExtensionAvg.description,
          text: `AVG Extension [${unitSymbolProvider(Enums.PitchingStats.ExtensionAvg.type, this.selectedUnitsSystem.system)}]`,
          value: Enums.PitchingStats.ExtensionAvg.key,
          align: 'center',
        },
        {
          title: Enums.PitchingStats.KZoneRate.description,
          text: Enums.PitchingStats.KZoneRate.short,
          value: Enums.PitchingStats.KZoneRate.key,
          align: 'center',
        },
        {
          title: Enums.PitchingStats.HitExitSpeedAvg.description,
          text: `${Enums.PitchingStats.HitExitSpeedAvg.name} [${unitSymbolProvider(Enums.PitchingStats.HitExitSpeedAvg.type, this.selectedUnitsSystem.system)}]`,
          value: Enums.PitchingStats.HitExitSpeedAvg.key,
          align: 'center',
        },
        {
          title: Enums.PitchingStats.HitsAllowed.description,
          text: Enums.PitchingStats.HitsAllowed.short,
          value: Enums.PitchingStats.HitsAllowed.key,
          align: 'center'
        },
        {
          title: Enums.PitchingStats.Strikeouts.description,
          text: Enums.PitchingStats.Strikeouts.short,
          value: Enums.PitchingStats.Strikeouts.key,
          align: 'center'
        },
        {
          title: Enums.PitchingStats.StrikeoutRate.description,
          text: 'SO%',
          value: Enums.PitchingStats.StrikeoutRate.key,
          align: 'center',
        },
        {
          title: Enums.PitchingStats.Swings.description,
          text: Enums.PitchingStats.Swings.short,
          value: Enums.PitchingStats.Swings.key,
          align: 'center'
        },
        {
          title: Enums.PitchingStats.Whiffs.description,
          text: 'Misses',
          value: Enums.PitchingStats.Whiffs.key,
          align: 'center'
        },
        {
          title: Enums.PitchingStats.SwingRate.description,
          text: Enums.PitchingStats.SwingRate.short,
          value: Enums.PitchingStats.SwingRate.key,
          align: 'center'
        },
        {
          title: Enums.PitchingStats.WhiffRate.description,
          text: Enums.PitchingStats.WhiffRate.short,
          value: Enums.PitchingStats.WhiffRate.key,
          align: 'center'
        },
        {
          title: Enums.PitchingStats.SwingingStrikeRate.description,
          text: Enums.PitchingStats.SwingingStrikeRate.short,
          value: Enums.PitchingStats.SwingingStrikeRate.key,
          align: 'center',
        },
        {
          title: Enums.PitchingStats.ZSwingRate.description,
          text: Enums.PitchingStats.ZSwingRate.short,
          value: Enums.PitchingStats.ZSwingRate.key,
          align: 'center',
        },
        {
          title: Enums.PitchingStats.ZContactRate.description,
          text: Enums.PitchingStats.ZContactRate.short,
          value: Enums.PitchingStats.ZContactRate.key,
          align: 'center',
        },
        {
          title: Enums.PitchingStats.OSwingRate.description,
          text: Enums.PitchingStats.OSwingRate.short,
          value: Enums.PitchingStats.OSwingRate.key,
          align: 'center',
        },
        {
          title: Enums.PitchingStats.OContactRate.description,
          text: Enums.PitchingStats.OContactRate.short,
          value: Enums.PitchingStats.OContactRate.key,
          align: 'center',
        },
        { value: 'data-table-expand' },
      ];
    },

    showExportBtn() {
      return this.$can('export', 'Csv') && this.showExport && this.player;
    },
  },

  methods: {
    getSummaryItem(key) {
      if (this.pitchingSummaryData) {
        return this.pitchingSummaryData.summary[key];
      }

      return undefined;
    },
  },
};
