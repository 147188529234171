<template>
<v-navigation-drawer permanent floating width="width" class="side-nav-with-filters">

  <template v-slot:prepend>
    <v-card dark tile flat color="#3d3935" class="pb-4">
    <v-card-title class="py-2">{{ i18n.labels.report_selector }}</v-card-title>
    <v-list-item>
      <v-select
        v-model="currentReport"
        :items="links"
        :menu-props="{ offsetY: true, maxHeight: 'auto' }"
        :prepend-inner-icon="currentReportIcon"
        return-object
        outlined
        solo
        light
        dense
        hide-details
        :disabled="links.length < 2"
      >
        <template v-slot:selection="{ item }">
          <div class="v-select__selection v-select__selection--comma">{{ $vuetify.lang.t(item.text) }}</div>
        </template>

        <template v-slot:item="{ item, attrs, on }">

          <v-list-item
            v-bind="attrs"
            v-on="on"
            :to="item.to"
          >
            <v-list-item-icon>
              <v-icon v-if="item.icon">$vuetify.icons.{{item.icon}}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>

              <v-list-item-content>
                <v-list-item-title
                  :id="attrs['aria-labelledby']"
                  class="subtitle-2 font-weight-bold"
                 >{{ $vuetify.lang.t(item.text) }}</v-list-item-title>
              </v-list-item-content>

            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>
    </v-list-item>
    </v-card>
  </template>

  <vertical-filters
    v-if="session"
    :session="session"
    :filters_key="filters_key"
    :refresh-session="refreshSession"
  ></vertical-filters>

</v-navigation-drawer>
</template>

<script>
import { Session} from '@/models/orm/Hierarchy';
import VerticalFilters from '../components/VerticalFilters.vue';

const i18n = {
  labels: {
    report_selector: 'Reports',
  }
};

export default {
  name: 'SideNavWithFilters',
  components: {
    VerticalFilters,
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
    session: {
      type: Session,
    },
    filters_key: {
      type: String,
      required: true,
    },
    refreshSession: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      width: 320,
      permanent: true,
      floating: true,
      currentReport: null
    };
  },
  created() {
    this.setCurrentReport();
  },
  computed: {
    i18n() {
      return i18n;
    },
    currentReportIcon() {
      if (!this.currentReport) {
        return null;
      }
      return `$vuetify.icons.${this.currentReport.icon}`;
    }
  },
  methods: {
    setCurrentReport() {
      this.currentReport = this.links.find((link) => link.to.name === this.$route.name);
    }
  },
   watch: {
    $route(to, from) {
      this.setCurrentReport();
    }
  },
};
</script>

<style lang="scss" scoped>
.v-list .v-list-item--active {
  background-color: #f6f6f6;
}

.v-card__title {
  font-size: 12px;
  opacity: 0.6;
}
</style>
