<template>
<div class="d-flex grey lighten-4 report-with-filters">
  <div
    class="scrolled-max-h"
    :class="[$style.column, $style.sidenavWidth]">

    <side-nav-with-filters
      v-if="session"
      class="view"
      :session="session"
      :refresh-session="refreshSession"
      :links="links"
      :filters_key="$route.meta.filters_key "
    ></side-nav-with-filters>

  </div>
  <div
    class="col scrolled-max-h report-column"
    :class="$style.column">
    <v-fade-transition tag="div" group class="">
      <router-view class="view"
        v-if="session.hasPlayersLoaded"
        v-bind="viewProps"
        :key="$route.name"
        @delete:video="id => $emit('delete:video', id)"
      ></router-view>
    </v-fade-transition>
  </div>
</div>
</template>

<script>
import { Session} from '@/models/orm/Hierarchy';
import SideNavWithFilters from '@/modules/session/views/SideNavWithFilters.vue';
import { Enums } from '@flightscope/baseball-stats';

export default {
  name: 'ReportWithFilters',

  components: {
    SideNavWithFilters,
  },

  props: {
    session: {
      type: Session,
      required: true,
    },
    refreshSession: {
      type: Function,
    },

    stats: Array,
  },

  computed: {
    links() {
      let route = this.$route;
      const type = route?.params?.type;

      if(route.matched?.length >= 2) {
        let parent = this.$route.matched.slice(-2).shift();

        let filtered = this.$router.getRoutes().filter(r => r?.parent?.name === parent.name);
        if (!this.session.mightHaveRadarData) {
          filtered = filtered.filter(
            r => ['session.report.hitting.summary', 'session.report.pitching.summary'].includes(r.name)
          );
        }

        if (this.session.sessionType !== Enums.SessionType.Game.key) {
          filtered = filtered.filter(
            r => !['session.report.pitching.post-game', 'session.report.hitting.post-game'].includes(r.name)
          );
        }

        let output = filtered.map((f => ({
          icon: f.meta.icon,
          text: f.meta.text,
          to: { name: f.name },
        })));

        return output;
      }

      return [];
    },

    viewProps() {
      let props = {
        session: this.session,
      };
      if (this.$route?.meta?.require) {
        props[this.$route.meta.require] = this[this.$route.meta.require];
      }
      return props;
    },
  },

  mounted() {
    this.$log.debug(this.$options.name, 'mounted');
  },
}
</script>

<style lang="scss" module>
.column {
  --max-h: calc(100vh - 60px - 101px - 48px);
  min-height: var(--max-h);
  display: flex;
  flex-direction: column;

  > .view {
    flex: 1;
  }
}

.sidenavWidth {
  max-width: 320px;
}
</style>
